.itemCard {
  touch-action: none;
  cursor: grab;
}

.draggingItem {
  border-style: dashed;

  :global(.card-body) {
    opacity: 0;
  }
}

.draggingHtml {
  scroll-behavior: auto;
}
