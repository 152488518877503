@import './variables';

.logoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.logoMobileContainer {
  z-index: $zindex-fixed + 5;

  @include media-breakpoint-up($expand-breakpoint) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
