@mixin text-uppercase() {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@mixin text-lowercase() {
  text-transform: lowercase;
  letter-spacing: 0.02em;
}

@mixin text-monospace() {
  @include text-uppercase();
  font-family: $font-family-monospace;
}

@mixin text-base() {
  text-transform: none;
  font-family: $font-family-base;
}
