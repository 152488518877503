@import './variables';

.stickyNavbar {
  position: fixed;
  padding-top: $top-space-sticky;
  padding-bottom: $top-space-sticky;

  &.isFixed {
    background-color: rgba(
      red($deep-dark),
      green($deep-dark),
      blue($deep-dark),
      1
    );
    box-shadow: $box-shadow-lg;

    @include media-breakpoint-down($collapse-breakpoint) {
      :global(.navbar) {
        padding: 0 1rem !important;
      }
    }
  }

  &.animateTransition {
    transition: transform ($slide-transition-duration * 1ms)
        $slide-transition-easing,
      box-shadow ($slide-transition-duration * 1ms) $slide-transition-easing,
      background-color ($slide-transition-duration / 2 * 1ms)
        $slide-transition-easing;
  }

  &.isHidden {
    box-shadow: none;
    transform: translate(0, -100%);
  }

  &.isOnTop {
    position: absolute !important;
    background-color: rgba(red($deep-dark), green($deep-dark), blue($deep-dark), 0);
    box-shadow: none;

    @include media-breakpoint-up($expand-breakpoint) {
      padding-top: $top-space-desktop !important;
      margin-bottom: ($top-space-desktop - $top-space-sticky) !important;
    }
  }
}
