@import '../assets/styles/shared.scss';

$timeout-color: 250ms * $animationTimeoutFactor;
$timeout-line: 333ms * $animationTimeoutFactor;

.animatedLink {
  &:hover,
  &:global(.active) {
    color: $text-color-base !important;
    text-decoration: none !important;
  }

  .animatedLinkText:not(:disabled):not(:global(.disabled)) {
    display: block;
    transform: translate3d(0, 0, 0);
    overflow: hidden;

    &:before{
      content: attr(data-name);
      color: $white;
      left: 0;
      position: absolute;
      width: 0%;
      overflow: hidden;
      z-index: 1;
      white-space: nowrap;
      transition: width $timeout-color $easeOutCirc, opacity $timeout-color $easeOutCirc;
    }

    &:after{
      content: '';
      background: $light-green;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      transform: translate3d(-105%, -100%, 0);
      transition: width $timeout-line $easeOutCirc, transform $timeout-line $easeOutCirc, opacity $timeout-line $easeOutCirc;
    }

    &:hover, &:global(.active) {
      &:before {
        width: 100%;
      }

      &:after{
        opacity: 1;
        transform: translate3d(105%, -100%, 0);
      }
    }
  }
}
