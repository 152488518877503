$blue-100: rgb(26, 21, 156); // #1A159C
$blue-200: rgb(50, 44, 190); // #322CBE
$blue-300: rgb(79, 74, 203); // #4F4ACB
$blue-400: rgb(110, 106, 217); // #6E6AD9
$blue-500: rgb(152, 149, 232); // #9895E8

$blues: (
  "100": $blue-100,
  "200": $blue-200,
  "300": $blue-300,
  "400": $blue-400,
  "500": $blue-500
) !default;

$neutral-gray:  rgb(230, 230, 230); // #E6E6E6
$light-white:   rgba(255, 255, 255, 0.66);
$light-green:   rgb(138, 255, 184); // #8AFFB8
$nord-green:    rgb(0,   255, 150); // #00FF96
$dark-green:    darken(saturate($nord-green, 1), 6); // #00e084
$pale-red:      rgb(255, 122, 125); // #FF7A7D
$sand:          rgb(220, 192, 161); // #DCC0A1
$dusk-blue:     rgb(78,   74, 203); // #4E4ACB
$nord-blue:     rgb(32,   32, 49);  // #202031
$deep-dark: darken(saturate($nord-blue, 1), 3.33); // #191927

$brand-colors: (
  "neutral-gray": $neutral-gray,
  "light-green": $light-green,
  "nord-green": $nord-green,
  "pale-red": $pale-red,
  "dusk-blue": $dusk-blue,
  "nord-blue": $nord-blue
) !default;

$text-color-light: white !default;
$text-color-base: $sand !default;
$text-color-dark: $nord-blue !default;

$font-sizes: (
  "xxxl": ((36 / 18) * 1em),
  "xxl":  ((30 / 18) * 1em),
  "xl":   ((24 / 18) * 1em),
  "lg":   ((20 / 18) * 1em),
  "md":   ((18 / 18) * 1em),
  "sm":   ((16 / 18) * 1em),
  "xs":   ((14 / 18) * 1em)
);

$font-sizes-desktop: (
  "xxxl": ((48 / 18) * 1em),
  "xxl":  ((40 / 18) * 1em),
  "xl":   ((32 / 18) * 1em),
  "lg":   ((24 / 18) * 1em)
);

$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$authenticMotion: cubic-bezier(.4, 0, .2, 1);

$animationTimeoutFactor: 1 !default;

$boxShadow: 0 0 32px 0 rgba(0, 0, 0, 0.05) !default;

$background-gradient: linear-gradient(0deg, $dusk-blue 0%, $nord-blue 100%);

@import "./bootstrap/variables";
@import "~bootstrap/scss/variables";
