@import "../assets/styles/shared";

$top-section-padding: 5rem;
$top-section-padding-lg: 10rem;

.fullScreenSection {
  width: 100%;
  height: 100%; // IE11 Hack
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .bufferTop {
    min-height: $top-section-padding;

    @include media-breakpoint-up(lg) {
      min-height: $top-section-padding-lg;
    }

  }

  .buffer {
    width: 100%;
    max-height: $top-section-padding;
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
      max-height: $top-section-padding-lg;
    }
  }

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &> :global(.container) {
      margin: 0!important;
    }
  }
}
