@import "../assets/styles/shared.scss";

$circle-padding: $btn-padding-y;

$circleSizeXs: 1.875rem;
$circleSizeSm: 3rem;
$circleSizeMd: 4rem;
$circleSizeLg: 6rem;
$circleSizeXl: 10rem;


$circle-sizes: (
  "xs": $circleSizeXs,
  "sm": $circleSizeSm,
  "md": $circleSizeMd,
  "lg": $circleSizeLg,
  "xl": $circleSizeXl
);
$circle-icon-sizes: (
  "xs": 0.8,
  "sm": 1,
  "md": 1.5,
  "lg": 1.8,
  "xl": 3
);

:export {
  circleSizeXs: strip-unit($circleSizeXs);
  circleSizeSm: strip-unit($circleSizeSm);
  circleSizeMd: strip-unit($circleSizeMd);
  circleSizeLg: strip-unit($circleSizeLg);
  circleSizeXl: strip-unit($circleSizeXl);
}

@function capitalize($string) {
  @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}

@mixin circle-variant($border) {
  border-color: $border;

  &>:global(.icon) {
    color: $border;
  }
}

@mixin circle-filled-variant($background) {
  background-color: $background;
  color: color-yiq($background);
  border-color: color-yiq($background);

  &>:global(.icon) {
    color: color-yiq($background);
  }
}

.circle {
  border-radius: 50%;
  border: 2px solid $white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  text-align: center;
  overflow-wrap: break-word;
  // padding: $circle-padding;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  height: map-get($circle-sizes, 'md');
  width: map-get($circle-sizes, 'md');

  & * {
    max-height: 6rem;
    max-width: 6rem;
  }

  &.circleBorderless {
    border: none;
  }

  .circleImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :global(.icon) {
    @include font-size(map-get($circle-icon-sizes, 'md') * 1em);
    stroke-width: 1 / map-get($circle-icon-sizes, 'md') * 3;
    margin-bottom: 0;
  }

  :global(.btn) {
    padding: 0;
    margin: 0;
    font-size: inherit;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@each $size, $value in $circle-sizes {
  .circle#{capitalize($size)} {
    height: $value;
    width: $value;
  }
}

@each $size, $value in $circle-icon-sizes {
  .circle#{capitalize($size)} {
    :global(.icon) {
      @include font-size($value * 1em);
      stroke-width: 1 / $value * 3;
    }
  }
}

@each $color, $value in $theme-colors {
  .circle#{capitalize($color)} {
    @include circle-variant($value);

    &.circleFilled {
      @include circle-filled-variant($value);
    }
  }
}
