@import '../assets/styles/shared.scss';

.descriptionList {
  margin-bottom: 0;
  dt {
    @include text-uppercase();
    opacity: (2/3);
    font-weight: normal;
  }

  dd,
  dt {
    font-size: $font-size-base;
  }

  dd:last-child {
    margin-bottom: 0px;
  }
}
